export const SANDWICH_JSON_DATA = 'SANDWICH_JSON_DATA';

export const FORM_COMPONENT_TYPE = {
  联系方式: 'phone',
  选择题: 'choise-question',
  输入框: 'textarea',
  分类: 'choise-cate',
  专业: 'choise-major',
  工作年限: 'choise-working-years',
  地区: 'choise-province',
};
export const POP_FORM_COMPONENT_TYPE = {
  弹窗联系方式: 'pop-phone',
};

// key 唯一标识 value 配置文件名
export const COMPONENT_TYPE = {
  图片: 'picture',
  文本: 'text',
  轮播图: 'swiper',
  表单: 'form',
  百度统计: 'baidu-statistics',
  弹窗表单: 'popup',





  ...FORM_COMPONENT_TYPE,
  ...POP_FORM_COMPONENT_TYPE,
};

export const QUESTION_TYPE = {
  单选题: 'radio',
  多选题: 'checkbox',
};
