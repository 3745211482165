<template>
  <div class="component__phone">
    <div class="title" :class="{ required: component.props.required }">
      {{ component.props.title }}
    </div>
    <div class="desc">{{ component.props.desc }}</div>
    <input
      v-model="name"
      type="text"
      minlength="6"
      placeholder="请输入姓名"
      class="input input__name"
    />
    <input
      v-model="tel"
      type="tel"
      maxlength="11"
      minlength="11"
      placeholder="请输入手机号码"
      class="input input__phone"
    />
    <div class="code-wrap">
      <input
        v-model="code"
        maxlength="6"
        minlength="6"
        type="tel"
        class="input input__code"
        placeholder="请输入验证码"
      />
      <button
        :style="component.buttonStyle"
        :disabled="disableSendCode"
        class="button"
        @click="sendCode"
      >
        <span v-if="disableSendCode">{{ codeCountdown }}秒</span>
        <span v-else>发送验证码</span>
      </button>
    </div>
  </div>
</template>

<script>
import { Message,Loading } from 'element-ui';
import { API_ADDRESS } from '@/editor/constants';
import Crypto from '@/common/crypto';

export default {
  name: 'ComponentPhone',

  props: {
    component: {
      type: Object,
      required: true,
    },
  },

  data() {
    this.timer = null;
    return {
      name: '',
      tel: '',
      code: '',
      codeCountdown: 0,
    };
  },

  computed: {
    disableSendCode() {
      return this.codeCountdown > 0;
    },
  },

  methods: {
    sendCode() {
      let reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.tel)) {
        Message('请输入有效的手机号码');
        return false;
      }
	  let loading = Loading.service({
          lock: true,
          text: '请稍后...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      const params = {};
      params.tel = this.tel;
      this.$axios({
        method: 'post',
        url: API_ADDRESS + 'landingpageapi/LandingPage/getCode',
        data: { param: Crypto.encrypt(JSON.stringify(params)) },
      }).then(res => {
		  loading.close()
          if (res.data.code == 200) {
            this.handleClickSendCode();
          } else if (res.data.code == 202) {
            Message({ message: res.data.msg, type: 'success', center: true });
          } else {
            Message({ message: res.data.msg, type: 'error', center: true });
          }
      });
    },
    getData() {
      return { tel: this.tel, code: this.code, name: this.name };
    },

    handleClickSendCode() {
      if (this.codeCountdown > 0) return;
      this.codeCountdown = 60;
      this.timer = setInterval(() => {
        this.codeCountdown -= 1;
        if (this.codeCountdown === 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.input {
  box-sizing: border-box;
  padding: 8px;
  border: 0;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  background-color: #f8f8f8;
  outline: 0;
  resize: none;
}

.input__phone,
.input__name {
  width: 100%;
}

.input__phone {
  margin-top: 10px;
}

.code-wrap {
  height: 40px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .input__code {
    flex: auto;
  }

  .button {
    flex: 0 0 100px;
    height: 40px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    background-color: @color-brand;

    &:disabled {
      // filter: grayscale(100%);
      background-color: #f8f8f8 !important;
      color: #999 !important;
      cursor: not-allowed;
    }
  }
}
</style>
