<template>
  <div>
    <button @click="dialogVisible = true" hidden id="openPopup"></button>
    <el-dialog :visible.sync="dialogVisible" width="90%" style="border-radius: 10px">
      <RenderPopup v-bind="$attrs" @submit="handleSubmit">
        <slot/>
      </RenderPopup>
    </el-dialog>
  </div>
</template>

<script>
import RenderPopup from '@/renderer/components/Popup.vue';
import { Message } from 'element-ui';
import { API_ADDRESS } from '@/editor/constants';

export default {
  name: 'PopupWrap',
  components: {RenderPopup},
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    handleSubmit(data) {
      this.$axios({
        method: 'post',
        url: API_ADDRESS + 'landingpageapi/LandingPage/submitForm',
        data: data,
      }).then(res => {
        if (res.data.code == 201) {
          Message({message: res.data.msg, type: 'warning', center: true});
        }
        if (res.data.code == 200) {
          this.dialogVisible = false;
          Message({message: res.data.msg, type: 'success', center: true});
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>

.input {
  box-sizing: border-box;
  padding: 8px;
  border: 0;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  background-color: #f8f8f8;
  outline: 0;
  resize: none;
}

.input__phone,
.input__name {
  width: 100%;
}

.input__phone {
  margin-top: 10px;
}

.code-wrap {
  height: 40px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .input__code {
    flex: auto;
  }

  .button {
    flex: 0 0 100px;
    height: 40px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    background-color: @color-brand;

    &:disabled {
      // filter: grayscale(100%);
      background-color: #f8f8f8 !important;
      color: #999 !important;
      cursor: not-allowed;
    }
  }
}
</style>
