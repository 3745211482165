<template>
  <div class="component__choice-major">
    <div class="title" :class="{ required: component.props.required }">
      专业
    </div>
    <div class="desc">{{ component.props.desc }}</div>
    <el-radio-group v-model="major" class="radio">
      <el-radio-button v-for="(option, index) in options" :name="`major${_uid}`" :key="index" :value="option.label" class="option" :label="option.label"></el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
import { QUESTION_TYPE } from '../constants';

export default {
  name: 'ComponentChoiseMajor',

  props: {
    component: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      major:'',
      options: JSON.parse(JSON.stringify(this.component.props.options)),
    };
  },

  watch: {
    'component.props.options': {
      deep: true,
      handler(v) {
        this.options = JSON.parse(JSON.stringify(v));
      },
    },
  },

  methods: {
    getData() {
      return { major: this.major };
    },

    handleChange(index) {
      if (this.component.props.quesType === QUESTION_TYPE.单选题) {
        this.options.forEach((o, i) => {
          if (i !== index) o.checked = false;
        });
        this.options[index].checked = true;
      } else {
        this.options[index].checked = !this.options[index].checked;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.component__choice-major {
  .radio {
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    outline: 0;
    width: 100%;
  }
  .is-active{
    outline: none !important;
  }
}
</style>
