<template>
  <div></div>
</template>
<script>
import { ONLY_TONGJI, TONGJI_KEFU } from '@/editor/constants';
export default {
  name: 'ComponentBaiduStatistics',
  props: {
    component: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    var _hmt = _hmt || [];
    var hm = document.createElement('script');
    hm.src =
      this.component.props.onlineService == true ? TONGJI_KEFU : ONLY_TONGJI;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(hm, s);
  },
};
</script>
