<template>
  <RenderForm v-bind="$attrs" @submit="handleSubmit">
    <slot />
  </RenderForm>
</template>

<script>
import RenderForm from '@/renderer/components/Form.vue';
import { Message,Loading } from 'element-ui';
import { API_ADDRESS } from '@/editor/constants';
import Crypto from '@/common/crypto';

export default {
  name: 'FormWrap',

  components: {
    RenderForm,
  },

  methods: {
    handleSubmit(data) {
      if (this.$route.query) {
        data = {
          ...data,
          ...this.$route.query,
        };
      }
	  let loading = Loading.service({
	      lock: true,
	      text: '请稍后...',
	      spinner: 'el-icon-loading',
	      background: 'rgba(0, 0, 0, 0.7)'
	    });
      this.$axios({
        method: 'post',
        url: API_ADDRESS + 'landingpageapi/LandingPage/submitForm',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: { param: Crypto.encrypt(JSON.stringify(data)) },
      }).then(res => {
		  loading.close();
          if (res.data.code == 201) {
            Message({
              message: res.data.msg,
              type: 'warning',
              center: true,
            });
          }
          if (res.data.code == 200) {
            Message({
              message: res.data.msg,
              type: 'success',
              center: true,
            });
          }
        
      });
    },
  },
};
</script>
