<template>
  <div class="component__picture">
    <img
      v-if="
        component.props.src.url && component.props.src.click === 'onlineService'
      "
      :src="component.props.src.url"
      class="picture"
      @click="onlineService"
    />
    <img
      v-if="component.props.src.url && component.props.src.click === 'popForm'"
      :src="component.props.src.url"
      class="picture"
      @click="openForm('DASDASD')"
    />
    <img
      v-if="component.props.src.url && component.props.src.click === ''"
      :src="component.props.src.url"
      class="picture"
    />
    <PlaceholderPicture v-else />
  </div>
</template>

<script>
import PlaceholderPicture from '@/common/components/PlaceholderPicture.vue';
import { popup, service} from '@/site/main';

export default {
  name: 'ComponentPicture',

  components: {
    PlaceholderPicture,
  },

  props: {
    component: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onlineService() {
      service();
    },
    openForm(title) {
      console.log(title);
      popup();
    },
  },
};
</script>

<style lang="less" scoped>
.component__picture {
  .flex-center();

  .picture {
    display: block;
    width: 100%;
  }

  .svg-icon {
    font-size: 24px;
    color: #dcdfe6;
  }
}
</style>
