export const MOBILE_PHONE_WIDTH = 375;
export const MOBILE_PHONE_HEIGHT = 585;
export const SITE_ADDRESS =
  process.env.NODE_ENV === 'production'
    ? 'https://4f068389a7.vicp.fun/'
    : 'http://192.168.14.4/';
export const API_ADDRESS =
  process.env.NODE_ENV === 'production'
    ? 'http://testwap.ahuyk.com/'
    : 'http://192.168.14.4/';
export const ONLY_TONGJI =
  'https://hm.baidu.com/hm.js?7b8c36a7ed133f04a47949e9a73b78eb';
export const TONGJI_KEFU =
  'https://hm.baidu.com/hm.js?cdeeea482168f9fad9842593052d9e35';

export const CDN_DOMIAN = "https://ahuyikao-pub.cdn.bcebos.com";

export const PAGE_CONFIG_PATH = "/ahuyikao-pub/project/landing_page/edit/";
