<template>
  <div id="app">
    <Renderer
      v-if="components"
      :components="components"
      :component-render-config="componentRenderConfig"
    />
  </div>
</template>

<script>
import { COMPONENT_TYPE, SANDWICH_JSON_DATA } from '@/renderer/constants';

import Renderer from '@/renderer/Renderer';
import FormWrap from './components/Form.vue';
import PopupWrap from './components/Popup.vue';
import { Message } from 'element-ui';
import { CDN_DOMIAN, PAGE_PATH } from '@/editor/constants';

function getUrlParam(name) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  let r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}

export default {
  name: 'App',

  components: {
    Renderer,
  },

  data() {
    this.componentRenderConfig = {
      [COMPONENT_TYPE.表单]: FormWrap,
      [COMPONENT_TYPE.弹窗表单]: PopupWrap,
    };
    return {
      components: null,
      id: getUrlParam('id'),
      isPreview: getUrlParam('isPreview'),
    };
  },
  created() {
    if (this.isPreview === 1) {
      const data = window.localStorage.getItem(SANDWICH_JSON_DATA);
      if (data) {
        const { components } = JSON.parse(data);
        if (components) this.components = components;
      }
    } else {
      if (!this.$route.params.id) {
        return;
      }
      this.$axios({
        method: 'get',
        url: CDN_DOMIAN + PAGE_PATH + this.$route.params.id + '.json',
      })
        .then(res => {
          if (res) {
            const { components } = res.data;
            console.log(res);
            if (components) this.components = components;
          } else {
            Message('无法访问');
          }
        })
        .catch({});
    }
  },
  mounted() {
    window.addEventListener('message', e => {
      const { components } = e.data;
      if (components) this.components = components;
    });
  },
};
</script>

<style src="normalize.css"></style>
<style lang="less" src="./app.less"></style>
<style lang="less">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.el-dialog {
  border-radius: 10px !important;
}
</style>
