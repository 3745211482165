import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import axios from 'axios';

import {
  Container,
  Header,
  Aside,
  Main,
  Button,
  Input,
  Select,
  Option,
  RadioGroup,
  RadioButton,
  Switch,
  ColorPicker,
  InputNumber,
  Dialog,
} from 'element-ui';
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Button);
Vue.use(Input);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Switch);
Vue.use(ColorPicker);
Vue.use(InputNumber);
Vue.use(Select);
Vue.use(Option);
Vue.use(Dialog);
Vue.use(VueRouter);
Vue.prototype.$axios = axios;

Vue.config.productionTip = false;
const routes = [{ path: '/:id', component: App }];

const router = new VueRouter({
  mode: 'history',
  routes,
});
new Vue({
  router,
  render: h => h(App),
}).$mount('#app');

export const service = () => {
  document.getElementById('nb_invite_ok').click();
};
export const popup = () => {
  document.getElementById('openPopup').click();
};
